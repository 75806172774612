import React from 'react'
import PokerLayout from '../../components/Poker/PokerLayout'
import { Breadcrumb } from '../../components/Breadcrumbs/Breadcrumbs'
import { graphql, useStaticQuery } from 'gatsby'
import { POKER, POKER_APP_DOWNLOAD } from '../../components/internal-links'
import RenderHTML from '../../components/Poker/PokerVarientPages/RenderHTML'
import { HOMEPAGE_BREADCRUMB_TITLE } from '../../utils/constnst.utils'

const PokerPage = () => {
  const data = useStaticQuery(graphql`
    query {
      allGhostPost(filter: { slug: { eq: "download-poker-app" } }) {
        nodes {
          html
          title
          meta_title
          meta_description
        }
      }
    }
  `)

  const title = data.allGhostPost.nodes[0]?.title
  const breadcrumbs: Breadcrumb[] = [
    {
      title: HOMEPAGE_BREADCRUMB_TITLE,
      url: '/',
    },
    {
      title: 'Poker Online',
      url: POKER,
    },
    {
      title: title,
      url: POKER_APP_DOWNLOAD,
    },
  ]

  const metaTitle = data.allGhostPost.nodes[0]?.meta_title
  const metaDescription = data.allGhostPost.nodes[0]?.meta_description

  return (
    <>
      <PokerLayout
        pageName="custom"
        breadcrumbs={breadcrumbs}
        pageSlug={{
          POKER_LINK: POKER_APP_DOWNLOAD,
        }}
        seoDetails={{
          title: metaTitle,
          description: metaDescription,
        }}
        englishContentHTML={
          <RenderHTML html={data.allGhostPost.nodes[0]?.html} />
        }
        mainBannerTitle={title}
        pokerBtnText={'Download'}
        pokerVariants={[
          {
            name: "Texas holde'm",
          },
          {
            name: 'Omaha Poker',
          },
        ]}
      />
    </>
  )
}

export default PokerPage
